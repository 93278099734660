.ant-checkbox-group-item {
    display: block;
}

.ant-checkbox-inner {
    border: 1px solid #5F6B78;
    background-color: #21282E
}

.ant-radio-button-wrapper {
    color: rgba(255, 255, 255, 0.85);
    background: #343c43;
    border: 1px solid #5F6B78;
    border-top-width: 1.02px;
    border-left-width: 0;
}

.ant-radio-button-wrapper {
    border-color: #5F6B78;
}
.ant-radio-button-wrapper:not(:first-child)::before {
    background:  #5F6B78;
}
.ant-radio-button-wrapper:first-child {
    border-left-color: #5F6B78;
}
.ant-radio-button-wrapper:last-child {
    border-right-color: #5F6B78;
}


.ant-checkbox-wrapper {
    color: rgba(255, 255, 255, 0.85);
}
