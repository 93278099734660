.ant-table-pagination.ant-pagination {
    float: left;
    margin: 16px 0 0;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item a {
    outline: none;
    width: 30px;
    height: 30px;
    line-height: 29px;
    border: 1px solid transparent;
    font-size: 10px;
    text-align: center;
    border-radius: 4px;
    background: transparent;
}

.ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border: 0;
    
}
.ant-pagination-item {
    background-color: transparent;
}

.ant-pagination-item:hover a,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: #fff;
    background: #40a9ff;
}

.ant-pagination-item a {
    display: block;
    margin: 0;
    color: #596570;
}


.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    background: #3C454D;
    border: 1px solid #3C454D;
    color: #7D91A5;
    font-size: 10px;
}

.ant-pagination-item-active {
    border-color: #2A343C;
    background: #2A343C;
    font-weight: 500;
}

.ant-pagination-item-active a,
.ant-pagination-item-active:hover a,
.ant-pagination-item-active a:hover {
    color: #fff;
    background: #2A343C;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
    cursor: default;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
    background: #2A343C;
    border-color: #2A343C;
    color: #7D91A5;
    cursor: default;
}


