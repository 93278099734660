.navigation {
  color: rgba(255, 255, 255, 0.65);
  background: #1D2329;

  flex: 1;
  width: 180px;
  overflow: hidden;
}

.navigation-inner {
  width: 200px;
  height: 100%;
  overflow-y: scroll;
}
