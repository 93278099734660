.ant-progress-text {
    display: block;
    margin: 4px 0 0 0;
    color: #798899;
}

.ant-progress-status-success .ant-progress-text {
    color: #32b373;
}

.ant-progress-status-success .ant-progress-bg {
    background-color: #32b373;
}
