.photo-upload {
    position: relative;
    display: block;
}

.photo-upload--full {
    /*width: 100%;
    height: 0;
    padding-bottom: 100%;*/
}

.photo-upload .ant-upload-list-picture-card-container,
.photo-upload .ant-upload-list-picture-card .ant-upload-list-item,
.photo-upload .ant-upload.ant-upload-select-picture-card {
    width: 176px;
    height: 176px;
}

.ant-form-item-control.has-success .photo-upload .ant-upload.ant-upload-select-picture-card {
    width: 176px;
    height: 32px;
    margin: 0;
}

.photo-upload-btn-icon {
    font-size: 32px;
    color: #5F6B78;
}

.photo-upload-btn-text {
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.25;
    color: #5F6B78;
}

.ant-form-item-control.has-success .photo-upload-btn {
    display: flex;
    align-items: center;
}

.ant-form-item-control.has-success .photo-upload-btn-icon {
    margin-right: 8px;
    font-size: 20px;
}

.ant-form-item-control.has-success .photo-upload-btn-text {
    margin-top: 0;
    font-size: 16px;
    line-height: 1;
}
