.photo {
    padding: 2px;
    border: 1px solid #798897;
}

.photo-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: 50% 50%;
    background-color: #798897;
}

.photo-verified {
    position: absolute;
    top: -8px;
    left: -10px;
    font-size: 24px;
    color: #4ECF01;
    background-color: #fff;
    border-radius: 50%;
}

.photo-empty {
    text-align: center;
    color: #2B343A;
    font-size: 12px;
}

.photo-empty .anticon {
    font-size: 24px;
}

.photo-empty--avatar, .photo-empty--avatar .anticon {
    font-size: 1.25em;
}
