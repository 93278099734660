@import "layout/index.css";
@import "form/index.css";
@import "modal.css";
@import "nav/index.css";
@import "table.css";
@import "popover.css";
@import "button.css";
@import "progress.css";
@import "steps.css";
@import "drawer.css";

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    color: #fff;
    background: #1D2329;
}

img {
    max-width: 100%;
}


::-webkit-scrollbar {
    width: 6px;

}

::-webkit-scrollbar-track {
    box-shadow: none;
    background: #343C43;
}

::-webkit-scrollbar-thumb {
    background-color: #5C6369;
    border-radius: 2em;
    outline: none;
}


.logo {
    height: 32px;
    background: rgba(255, 255, 255, .2);
    margin: 16px;
}

.action-button {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.action-button-dropdown {
    font-size: 22px;
}
.action-button-btn {
    margin-right: 5px;
}


.ant-tabs-bar {
    border-bottom: none;
    margin-bottom: 1px;
}


.ant-tag {
    padding: 0 5px;
    color: #9ab2c1;
    border: 1px solid #5F6B78;
    background: #21282E;
    display: flex;
    align-items: center;
}

.ant-tag span {
    flex: 1;
}

.ant-tag .anticon-close {
    position: relative;
    left: 2px;
    color: #5F6B78;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #5F6B78;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #5F6B78;
}

.spin-inline {
    display: inline-block;
}

.ant-collapse {
    color: #fff;
    background-color: #2C343A;
    border: 1px solid #5F6B78;
    border-bottom: 0;
    border-radius: 12px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #fff;
}

.ant-collapse-content {
    border-top: 1px solid #5F6B78;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #5F6B78;
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 12px 12px;
}

.ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 12px 12px;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}


a {
    color: #40a9ff;
}

a:hover {
    color: #69c0ff;
}

.ant-switch {
    
}

.ant-switch-handle:before {
    background-color: #343c43;
}

.ant-switch-checked {
    background-color: #40a9ff;
}
