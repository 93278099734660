.ant-drawer-header {
    background: #343c43;
    border-bottom: 1px solid #5f6b78;
}

.ant-drawer-title {
    color: #fff;
}

.ant-drawer-close {
    color: rgba(255, 255, 255, 0.45);
}

.ant-drawer-content {
    background-color: #343c43;
}
