.distrcits {
    width: 100%;
    column-count: 3;
}

.photo {
    margin-bottom: 4px;
    padding: 4px;
    border: 1px solid #798897;
}
.photo > div {
    background-color: #798897;
}

.props {
    margin-top: 8px;
    font-size: 12px;
}