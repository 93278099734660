.profileValueName {
    display: block;
    margin-bottom: 5px;
}

.profileValuePhone {
    display: block;
    margin-bottom: 5px;
}

.profileValuePhoto {
    margin-bottom: 5px;
}

.profileValueUser {
    display: block;
    color: #fff;
}

.profileValueUser span {
    color: #798897;
}