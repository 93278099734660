.ant-steps-item-subtitle {
    display: block;
    margin: 0;
    color: #798899;
    font-weight: normal;
    font-size: 10px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.85);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.85);
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #2B343A;
    border-color: #1890ff;
}
