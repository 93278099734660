.ant-input-group-addon {
    color: #5f6f82;
    text-align: center;
    background-color: #2f3841;
    border: 1px solid #3F484E;
}

.ant-input {
    font-size: 12px;
    color: #fff;
    background-color: #21282E;
    background-image: none;
    border: 1px solid #3F484E;
}

.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    color: #fff;
    background-color: #21282E;
}

.ant-input-number {
    color: #fff;
    background-color: #21282E;
    border: 1px solid #3F484E;
}

.ant-input-affix-wrapper {
    color: #5f6f82;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 8px;
}

.ant-input-affix-wrapper {
    background-color: #21282E;
    background-image: none;
    border: 1px solid #3F484E;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #21282E inset !important;
    -webkit-text-fill-color: #fff !important;
}
