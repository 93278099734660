.status {
    display: inline-block;
    min-width: 60px;
    min-height: 22px;
    text-align: center;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    border: 1px solid #8c9dad;
    border-radius: 2em;
    white-space: nowrap;

    color: #eee;
    fill: #eee;
    background: #697683;
    user-select: none;
}

.status.size--small, .status.size--xs {
    min-height: 16px;
    padding: 1px 4px 2px;
    font-size: 10px;
    line-height: 16px;
}

.status.active {
    color: #32b373;
    fill: #32b373;
    border-color: #7ab3a1;
    background: rgba(50, 179, 115, 0.15);
}
