.ant-modal-content {
    background-color: #343c43;
}

.ant-modal-header {
    background-color: #343c43;
    border-bottom: 1px solid #5F6B78;
}

.ant-modal-footer {
    border-top: 1px solid #5F6B78;
}

.ant-modal-title {
    color: #fff;
}
