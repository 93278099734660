.props {
    font-size: 12px;
}

.date {
    font-size: 12px;
}

.sites {
    font-size: 12px;
}
.sitesCity {
    margin-bottom: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid #5F6B74;
}
.sitesCityLabel {
    display: block;
    margin-right: 4px;
    color: #798897;
}
.sitesList {
    margin-bottom: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid #5F6B74;
}
.sitesListLabel {
    display: block;
    margin-right: 4px;
    color: #798897;
}
.sitesExternalSite {
    
}
.sitesExternalSiteLabel {
    display: block;
    margin-right: 4px;
    color: #798897;
}

.status {
    margin-bottom: 4px;
}