.main {
    display: flex;
    margin: 0 -4px;
}

.group {
    padding: 0 4px;
}

.propLabel {
    font-weight: 600;
    color: #7B8899;
}
